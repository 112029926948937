<template>
  <div
    class="main-article"
    element-loading-text="正在生成页面"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <div class="printbtn">
      <p style="color: red">注意事项：</p>
      <p>如果需要导出PDF文件，则在页面打印设置中,选择保存到PDF文件</p>
    </div>
    <div
      class="content PageNext page"
      id="content_a"
      v-for="(item, key) in tableInfoList"
      :key="key"
      :style="{ fontSize }"
    >
      <!-- 医院名称 -->
      <div class="units_name" style="margin-bottom: 30px">
        <p style="font-size: 33px; font-weight: 700; text-align: center">
          {{ config.unit_name }}
        </p>
      </div>
      <!-- 普通报告 -->
      <div v-if="!item.results">
        <!-- 标题 -->
        <div class="report-title">
          <p>{{ item.measure_title }}</p>
        </div>
        <!-- 用户信息 -->
        <div class="userInfo" v-show="form.scope_basic == 1">
          <table>
            <tr>
              <td>
                姓名：
                <span>{{ item.customer.name }}</span>
              </td>
              <td>
                性别：
                <span>{{ item.customer.sex }}</span>
              </td>
              <td>
                出生日期：
                <span>{{ item.customer.birthday }}</span>
              </td>
            </tr>
            <tr>
              <td>
                编号：
                <span>{{ item.customer.account }}</span>
              </td>
              <td>
                科室：
                <span>{{ item.customer.department }}</span>
              </td>
              <td>
                报告日期：
                <span>{{ item.customer.evaluating_time }}</span>
              </td>
            </tr>
            <template v-if="item.customer.archives">
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <!-- 测试-表 -->
        <div class="table" v-show="form.scope_factor == 1">
          <div class="table-main">
            <el-row v-if="item.factor_results.length <= 3">
              <!-- 因子数小于三 -->
              <el-col>
                <table class="one">
                  <tr>
                    <th>检测项目</th>
                    <th>程度</th>
                    <th>得分</th>
                  </tr>
                  <!-- -------------检测项目---------------- -->
                  <tr v-for="(item2, key2) in item.factor_results" :key="key2">
                    <td>{{ item2.name }}</td>
                    <td>{{ item2.mark }}</td>
                    <td>{{ item2.score }}</td>
                  </tr>
                </table>
              </el-col>
            </el-row>
            <el-row v-if="item.factor_results.length > 3">
              <table class="results-two" cellspacing="0">
                <thead>
                  <tr>
                    <th>检测项目</th>
                    <th>程度</th>
                    <th>得分</th>
                    <th>检测项目</th>
                    <th>程度</th>
                    <th>得分</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item2, key2) in item.factor_results" :key="key2">
                    <template v-if="item.factor_results[key2 * 2]">
                      <td>{{ item.factor_results[key2 * 2].name }}</td>
                      <td>{{ item.factor_results[key2 * 2].mark }}</td>
                      <td>{{ item.factor_results[key2 * 2].score }}</td>
                    </template>
                    <template v-if="item.factor_results[key2 * 2 + 1]">
                      <td>{{ item.factor_results[key2 * 2 + 1].name }}</td>
                      <td>{{ item.factor_results[key2 * 2 + 1].mark }}</td>
                      <td>{{ item.factor_results[key2 * 2 + 1].score }}</td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </el-row>
          </div>
        </div>
        <!-- 测试结果title -->
        <p
          class="test-msg"
          v-show="
            form.scope_explain != 0 ||
            form.scope_explain != 0 ||
            form.scope_suggest != 0
          "
        >
          报告分析:
        </p>
        <!-- 每个项目分析 -->
        <div
          class="analyze"
          v-show="
            form.scope_explain != 0 ||
            form.scope_explain != 0 ||
            form.scope_suggest != 0
          "
        >
          <!-- v-show  隐藏没内容的 因子 -->
          <table
            v-for="(item2, key2) in item.factor_results"
            :key="key2"
            v-show="item2.comment != '' || item2.advice != ''"
          >
            <!--  v-show="item2.comment" -->
            <tr class="analyze-title" v-show="form.scope_score == 1">
              【{{
                item2.name
              }}】 分数级别：{{
                item2.mark
              }}
              <span>得分：{{ item2.score }}</span>
              <!-- v-if="item2.comment" -->
            </tr>
            <tr v-if="item2.comment" v-show="form.scope_explain == 1">
              <td class="analyze-title">【说 明】</td>
            </tr>
            <tr v-if="item2.comment" v-show="form.scope_explain == 1">
              <td v-html="item2.comment"></td>
            </tr>
            <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
              <td class="analyze-title">【指导建议】</td>
            </tr>
            <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
              <td v-html="item2.advice"></td>
            </tr>
          </table>
        </div>
        <!-- 配合程度 -->
        <div class="degree" style="margin: 10px 0" v-if="isDegree">
          <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
          <p style="text-indent: 2em">{{ degree }}</p>
        </div>
        <!-- 用户答题 -->
        <div
          class="user-answers"
          v-if="item.question && form.user_scope_question == 1"
        >
          <p class="test-msg">用户答案:</p>
          <div
            class="user-answers-item"
            v-for="(item2, key) in item.question"
            :key="key"
          >
            <div class="title" v-html="item2.question"></div>
            <span class="answers" v-html="'【答案】:' + item2.answer"></span>
          </div>
        </div>
        <!-- 医生签名 -->
        <div class="signature">
          <span class="title">
            <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
              >测评师:_______________</span
            >
            报告医生 ：
            <span class="signature_img">
              <img :src="item.admin.sign_img" alt width="150" />
            </span>
          </span>
          <span class="title data">报告日期 ：</span>
          <span>{{ item.customer.evaluating_time }}</span>
        </div>
      </div>
      <!-- 明尼苏达报告 -->
      <div v-if="item.results" class="content minnesota" :style="{ fontSize }">
        <!-- 标题  -->
        <div class="report-title">
          <p>{{ item.measure_title }}</p>
        </div>
        <!-- 用户信息 -->
        <div class="userInfo" v-show="form.scope_basic == 1">
          <table>
            <tr>
              <td>
                姓名：
                <span>{{ item.customer.name }}</span>
              </td>
              <td>
                性别：
                <span>{{ item.customer.sex }}</span>
              </td>
              <td>
                出生日期：
                <span>{{ item.customer.birthday }}</span>
              </td>
            </tr>
            <tr>
              <td>
                编号：
                <span>{{ item.customer.account }}</span>
              </td>
              <td>
                科室：
                <span>{{ item.customer.department }}</span>
              </td>
              <td>
                报告日期：
                <span>{{ item.customer.evaluating_time }}</span>
              </td>
            </tr>
            <template v-if="item.customer.archives">
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                  :key="key"
                >
                  {{ item2.name }} :
                  <span>{{ item2.value }}</span>
                </td>
              </tr>
            </template>
            <tr>
              <td>
                项目数:
                <span>{{
                  item.results.mmpi.statistics.dashi +
                  item.results.mmpi.statistics.dafou
                }}</span>
              </td>
              <td>
                答是数量:
                <span>{{ item.results.mmpi.statistics.dashi }}</span>
              </td>
              <td>
                答否数量:
                <span>{{ item.results.mmpi.statistics.dafou }}</span>
              </td>
            </tr>
          </table>
        </div>
        <br />
        <!-- MMPI 基 本 量 表 分 -->
        <div class="mmpi">
          <div class="title">MMPI 基 本 量 表 分</div>
          <el-divider></el-divider>
          <div class="mmpi_table">
            <!-- 2 -->
            <table>
              <tr>
                <td>
                  <span></span>
                </td>
                <td
                  v-for="(res, key, index) in item.results.mmpi.help"
                  :key="index"
                >
                  <span>{{ key }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>分数：</span>
                </td>
                <td
                  v-for="(res, key, index) in item.results.mmpi.help"
                  :key="index"
                >
                  <span>{{ res }}</span>
                </td>
              </tr>
            </table>
            <el-divider></el-divider>
            <!-- 4 -->
            <table>
              <tr>
                <td>
                  <span>原始分：</span>
                </td>
                <td
                  v-for="(res, key, index) in item.results.mmpi.oldCore"
                  :key="index"
                >
                  <span>{{ res }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>K 校 正 分：</span>
                </td>
                <td
                  v-for="(res, key, index) in item.results.mmpi.kCore"
                  :key="index"
                >
                  <span>{{ res }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>中国常模不做K校正的T分：</span>
                </td>
                <td
                  v-for="(res, key, index) in item.results.mmpi.kNoCmCore"
                  :key="index"
                >
                  <span>{{ res }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>中国常模做K校正的T分：</span>
                </td>
                <td
                  v-for="(res, key, index) in item.results.mmpi.kCmCore"
                  :key="index"
                >
                  <span>{{ res }}</span>
                </td>
              </tr>
            </table>
            <el-divider></el-divider>
            <div class="mmpi_summarize">
              <span>两点峰型:{{ item.results.mmpi.statistics.twofx }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>矛盾题:{{ item.results.mmpi.statistics.maodun }}</span>
              <el-divider direction="vertical"></el-divider>
              <span
                >答"是"的比例:{{ item.results.mmpi.statistics.pro_yes }} %</span
              >
              <el-divider direction="vertical"></el-divider>
              <span
                >答"否"的比例:{{ item.results.mmpi.statistics.pro_no }} %</span
              >
            </div>
            <el-divider></el-divider>
          </div>
        </div>
        <br />
        <!-- T 分 顺 序 表 -->
        <div class="t">
          <div class="title">T 分 顺 序 表</div>
          <el-divider></el-divider>
          <div class="t_table">
            <!-- 分 -->
            <table>
              <tr>
                <td
                  v-for="(res, key, index) in item.results.tcore.core"
                  :key="index"
                >
                  <span>{{ key }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(res, key, index) in item.results.tcore.core"
                  :key="index"
                >
                  <span>{{ res }}</span>
                </td>
              </tr>
            </table>
            <!-- 表格 -->
            <table>
              <tr>
                <td>因子量表</td>
                <td>T 分</td>
                <td>低分者特征</td>
                <td>
                  <span>10</span>
                  <span>20</span>
                  <span>30</span>
                  <span>40</span>
                  <span>50</span>
                  <span>60</span>
                  <span>70</span>
                  <span>80</span>
                  <span>90</span>
                  <span>100</span>
                  <span>110</span>
                </td>
                <td>高分者特征</td>
              </tr>
              <tr v-for="(res, key2) in item.results.tcore.tdes" :key="key2">
                <!-- 因 返回的数据 0-3为 一条td，故 %3 处理 不显示  -->
                <template v-if="key2 % 3 == 0">
                  <!-- 因子量表 -->
                  <td>
                    {{ res.tagdes }}
                    <br />
                    <span
                      v-html="item.results.tcore.tdes[key2 + 1].tagdes"
                    ></span>
                  </td>
                  <!-- 分数 -->
                  <td>{{ res.score }}</td>
                  <!-- 低分者特征 -->
                  <td>
                    {{ res.ddesc }}
                    <br />
                    {{ item.results.tcore.tdes[key2 + 1].ddesc }}
                  </td>
                  <!-- 游标 -->
                  <td>
                    <span
                      class="cursor"
                      :style="`left:${
                        (res.score / 10) * 25.91 - 25.91 / 2 - 4
                      }px`"
                    >
                      <i class="el-icon-caret-top"></i>
                    </span>
                  </td>
                  <!-- 高 分者特征 -->
                  <td>
                    {{ res.gdesc }}
                    <br />
                    {{ item.results.tcore.tdes[key2 + 1].gdesc }}
                  </td>
                </template>
              </tr>
            </table>
            <br />
            <!-- 图表 -->
            <div class="chart seal" v-show="form.scope_graph == 1">
              <!-- 90,圆形图 -->
              <div
                :id="'chart-report' + key"
                :style="{ width: '100%', height: '100%' }"
              ></div>
            </div>
            <!-- 预测内容 -->
            <div class="forecast" v-if="item.results.war">
              <table>
                <caption>
                  危险行为预测
                </caption>
                <tr>
                  <td style="text-align: center">预测内容</td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td>10</td>
                </tr>
                <tr v-for="(res, k, index) in item.results.war" :key="index">
                  <td style="text-align: left">{{ k }}:</td>
                  <td
                    v-for="index2 in 10"
                    :key="index2"
                    :colspan="res"
                    style="background: rgb(63, 62, 62)"
                    v-show="index2 == 1"
                  ></td>
                </tr>
                <!-- 程度 -->
                <tr style="transform: translateX(4px); font-weight: 700">
                  <td></td>
                  <td>小</td>
                  <td></td>
                  <td></td>
                  <td>中</td>
                  <td></td>
                  <td>大</td>
                  <td></td>
                  <td>极大</td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <br />
        <!-- 临 床 亚 量 表 -->
        <div class="conclusion">
          <table>
            <caption>
              临 床 亚 量 表
            </caption>
            <tr>
              <th>
                <span>量表</span>
              </th>
              <th>
                <span>T分</span>
              </th>
              <th>
                <span>量表结果的解释</span>
              </th>
            </tr>
            <tr v-for="(r, k, index) in item.results.lc" :key="index">
              <td>{{ r.name }}</td>
              <td>
                <span>{{ r.score }}</span>
              </td>
              <td>{{ r.comment }}</td>
            </tr>
          </table>
          <div class="remind">
            <p>
              注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
            </p>
          </div>
        </div>
        <!-- 附加量表 -->
        <div class="conclusion" v-if="item.results.fjlb">
          <table>
            <caption>
              附 加 量 表
            </caption>
            <tr>
              <th>
                <span>量表</span>
              </th>
              <th>
                <span>T分</span>
              </th>
              <th>
                <span>量表结果的解释</span>
              </th>
            </tr>
            <tr v-for="(r, k2) in item.results.fjlb" :key="k2">
              <td>{{ r.name }}</td>
              <td>
                <span>{{ r.score }}</span>
              </td>
              <td>{{ r.comment }}</td>
            </tr>
          </table>
          <div class="remind">
            <p>
              注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
            </p>
          </div>
        </div>
        <!-- 适应不良量表 -->
        <div class="conclusion" v-if="item.results.sybllb">
          <table>
            <caption>
              适 应 不 良 量 表
            </caption>
            <tr>
              <th>
                <span>量表</span>
              </th>
              <th>
                <span>T分</span>
              </th>
              <th>
                <span>量表结果的解释</span>
              </th>
            </tr>
            <tr v-for="(r, k2) in item.results.sybllb" :key="k2">
              <td>{{ r.name }}</td>
              <td>
                <span>{{ r.score }}</span>
              </td>
              <td>{{ r.comment }}</td>
            </tr>
          </table>
          <div class="remind">
            <p>
              注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
            </p>
          </div>
        </div>
        <!-- 预测性量表 -->
        <div class="conclusion" v-if="item.results.ycxlb">
          <table>
            <caption>
              预 测 性 量 表
            </caption>
            <tr>
              <th>
                <span>量表</span>
              </th>
              <th>
                <span>T分</span>
              </th>
              <th>
                <span>量表结果的解释</span>
              </th>
            </tr>
            <tr v-for="(r, k2) in item.results.ycxlb" :key="k2">
              <td>{{ r.name }}</td>
              <td>
                <span>{{ r.score }}</span>
              </td>
              <td>{{ r.comment }}</td>
            </tr>
          </table>
          <div class="remind">
            <p>
              注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
            </p>
          </div>
        </div>
        <!-- 性功能和性体验障碍量表 -->
        <div class="conclusion" v-if="item.results.xgn">
          <table>
            <caption>
              性 功 能 和 性 体 验 障 碍 量 表
            </caption>
            <tr>
              <th>
                <span>量表</span>
              </th>
              <th>
                <span>T分</span>
              </th>
              <th>
                <span>量表结果的解释</span>
              </th>
            </tr>
            <tr v-for="(r, k2) in item.results.xgn" :key="k2">
              <td>{{ r.name }}</td>
              <td>
                <span>{{ r.score }}</span>
              </td>
              <td>{{ r.comment }}</td>
            </tr>
          </table>
          <div class="remind">
            <p>
              注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
            </p>
          </div>
        </div>
        <!-- 内容量表 -->
        <div class="conclusion" v-if="item.results.nrlb">
          <table>
            <caption>
              内 容 量 表
            </caption>
            <tr>
              <th>
                <span>量表</span>
              </th>
              <th>
                <span>T分</span>
              </th>
              <th>
                <span>量表结果的解释</span>
              </th>
            </tr>
            <tr v-for="(r, k2) in item.results.nrlb" :key="k2">
              <td>{{ r.name }}</td>
              <td>
                <span>{{ r.score }}</span>
              </td>
              <td>{{ r.comment }}</td>
            </tr>
          </table>
          <div class="remind">
            <p>
              注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
            </p>
          </div>
        </div>
        <br />
        <!--辅助诊断意见 -->
        <div class="auxiliary" v-if="item.results.xlzz">
          <div class="title">* * *辅助诊断意见(仅供临床参考)* * *</div>
          <el-divider></el-divider>
          <div class="auxiliary_title">* * *心理症状提示* * *</div>
          <el-divider></el-divider>
          <div class="auxiliary_main">
            <div
              class="auxiliary_item"
              v-for="(r2, k2) in item.results.xlzz"
              :key="k2"
            >
              <div class="item_title">
                {{ k2 + 1 }}、{{ r2.title }}({{ r2.fz }}/{{ r2.fm }})：
              </div>
              <div class="item_content" v-html="r2.desc"></div>
            </div>
          </div>
        </div>
        <br />
        <!-- 个性心理描述 -->
        <div class="describe">
          <div class="title">* * * 个性心理描述 * * *</div>
          <el-divider></el-divider>
          <!-- 测谎 -->
          <div class="describe_main">
            <div class="describe_content_title">
              <p>测谎</p>
              <el-divider></el-divider>
            </div>
            <div class="describe_content">
              <p v-html="item.results.gxdesc.l"></p>
            </div>
          </div>
          <!-- 总效度分析 -->
          <div class="describe_main">
            <div class="describe_content_title">
              <p>总效度分析</p>
              <el-divider></el-divider>
            </div>
            <div class="describe_content">
              <p v-html="item.results.gxdesc.fx1"></p>
            </div>
          </div>
          <!-- 个性逐项分析 -->
          <div class="describe_main">
            <div class="describe_content_title">
              <p>个性逐项分析</p>
              <el-divider></el-divider>
            </div>
            <div class="describe_content">
              <p v-html="item.results.gxdesc.fx2"></p>
            </div>
          </div>
          <!-- 个性综合分析(本人常意识不到,应激,虚弱和疾病时明显) -->
          <div class="describe_main">
            <div class="describe_content_title">
              <p>个性综合分析(本人常意识不到,应激,虚弱和疾病时明显)</p>
              <el-divider></el-divider>
            </div>
            <div class="describe_content">
              <p v-html="item.results.gxdesc.fx3"></p>
            </div>
          </div>
        </div>
        <div class="remind">
          <p style="text-align: center">
            ******（本报告仅供临床参考，不作诊断证明之用）******
          </p>
        </div>
        <!-- 总 体 描 述 -->
        <div class="zDescribe" v-if="item.results.ztyx">
          <div class="zDescribe_title">总 体 描 述</div>
          <el-divider></el-divider>
          <div class="zDescribe_content">
            <p>{{ item.results.ztyx.desc }}</p>
          </div>
          <div class="zDescribe_msg">
            <p>
              检 查 结 果:
              <span>{{ item.results.ztyx.con }}</span>
            </p>
          </div>
        </div>
        <!-- 配合程度 -->
        <div class="degree" style="margin: 10px 0" v-if="isDegree">
          <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
          <p style="text-indent: 2em">{{ degree }}</p>
        </div>
        <!-- 医生建议 -->
        <br />
        <div class="suggest seal">
          <p class="suggest_msg">医生建议：</p>
          <div class="suggest_content"></div>
          <!-- 医生签名 -->
          <div class="signature">
            <span class="title">
              <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                >测评师:_______________</span
              >
              报告医生 ：
              <span class="signature_img">
                <!-- <img :src="item.admin.sign_img" alt width="150" /> -->
              </span>
            </span>
            <span class="title data">报告日期 ：</span>
            <span>{{ item.customer.evaluating_time }}</span>
          </div>
        </div>
        <div class="remind">
          <p style="text-align: center">
            ******此报告仅供参考，请以医师诊断为准。******
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadReport } from "@/api/report.js";
export default {
  data() {
    return {
      isDegree: false,
      degree: "",
      article: "",
      fullscreenLoading: true,
      userReporData: {
        customer: {
          id: 1,
          account: "iEfoam",
          name: "iEfoam",
          sex: "男",
          birthday: "1997-05-29",
          department_id: 1,
          unit_name: "重庆医科大学附属第二医院",
          department_name: "技术部门",
          age: 22,
        },
        measure_title: "心理健康临床症状自评量表(SCL-90)",
        theme: 3,
        base_results: {
          name: "心理健康状况",
          score: 30.3,
          factor_id: 222,
          mark: "心理健康状况良好",
          comment:
            "测试结果表明被测者的心理健康状况良好。被测者很少因心理原因出现身体不适感。能积极面对社会和生活中的各种问题，心理状态好，信任他人，对人友善。一般没有过于焦虑、悲伤等情况。总之，被测者拥有比较健康的心态和情绪，能够较好地处理生活中的挫折和压力。能很好的适应工作、社会生活，并从中获得满足和快乐，个人价值感和幸福感较高。",
          advice:
            "被测者的心理健康状况良好。希望被试继续保持这种积极的心态和良好的行为方式。健康对我们每个人的生活和工作都起着重要的作用，好的身体来源于健康的心态，能解除心理疲劳。好的心态有助于我们克服困难，即使受到挫折与坎坷，依然能够保持乐观的情绪，保持旺盛的斗志。你也可以从以下方面进一步提高你的生活质量，如多与人为善，建立完善的人际网络作为自己心理支持的后盾，掌握一些保持身心健康的常识和技巧，保持乐观、自信的心态面对生活等。",
        },
        warning: 0,
        factor_results: [
          {
            name: "躯体化",
            score: 2.5,
            factor_id: 212,
            mark: "主观感觉身体轻度不适",
            comment:
              "测试结果表明被测者主观感觉身体轻度不适。被测者会经常感到身体某些部位不适，比如心血管、胃肠道、呼吸和其他系统的不适或头晕、头痛、心慌、胸闷、胃部不适等。症状严重的被测者可能因此会反复去医院检查，但都没有任何结果，而被测者认为这些症状是躯体性的（器质性病变所引起）要求进一步检查。",
            advice:
              "被测者主观感觉身体不适。被测者应该首先到医院检查看是否有器质性病变，如果没有器质性病变，则应注意这些症状出现前是否承受了巨大心理压力，或者长期以来都有心理困扰，如果存在这些心理压力很可能是心理因素的躯体化表现。这时，减轻躯体不适的关键是消除这些不健康的心理因素。被测者即不应该忽视身体的不适，也不必过分关注和夸大这些不适，而应“对症下药”。保持心情愉悦、正确认识和应对压力、适度的体育锻炼，对维持身心健康必不可少。",
          },
          {
            name: "强迫症状",
            score: 3.5,
            factor_id: 213,
            mark: "存在中度强迫症状",
            comment:
              "测试结果表明被测者在生活中经常感到自身存在中度强迫性的想法或行为，即明知不必要却无法克制地要去想或要去做某事。比如无法停止反复想没有意义的事情；经常没有必要地反复做某些事情，例如检查门窗，开关，煤气，钱物，文件，表格，信件等；经常反复洗手而且洗手的时间很长，超过正常所需要；经常对病菌和各种疾病敏感，并毫无必要的担心等。这些强迫想法和行为让被测者感到非常痛苦和焦虑，他们总是力图摆脱但往往越是企图抵制，反到感到紧张和痛苦。",
            advice:
              "被测者在生活中经常感到自身存在中度强迫性的想法或行为。运用“听其自然”方法，不要苛求自己，该怎么办就怎么办，做了以后就不要再去想它，也不要去评价它，议论它。如果出现异常观念和行为要从认知上进行调节，要么完全接受它，顺其自然，要么通过夸张的想法，使其达到荒诞透顶的程度，以致自己也觉得很可笑，由此消除强迫想法或行为。当自己处于莫名其妙的紧张和焦虑状态时可以进行自我暗示，说服自己不要紧张。当出现强迫现象采用其它活动来转移或直接对抗强迫思维，如高声唱歌，背诵诗词等。被测者还可以寻求专业心理工作者的帮助。",
          },
          {
            name: "人际关系敏感",
            score: 2.33,
            factor_id: 214,
            mark: "存在轻度人际关系敏感症状",
            comment:
              "测试结果表明被测者存在轻度人际关系敏感的问题。被测者在与人交往时缺乏自信，敏感多虑，和人相处谨小慎微。容易和他人保持一定距离，虽然自己也希望拥有“知心朋友”，但又难与人建立亲密关系。与人相处很不自然，小心翼翼，害怕带给人麻烦，害怕别人嫌弃自己。有时会表现得特别有礼貌，实则给人距离和拘谨。很在意他人对自己的评价，很关注自己的言行是否得当。有的表现为特别害怕与异性交往。",
            advice:
              "被测者存在人际关系敏感的问题。被测者应加强在人际交往过程中的信心，不要过分注意自己的言行是否妥当，也不必太过在意他人对你的评价，应自然、自信的交往。多找些自己和他人的长处，同时正视自己的缺点，遇到让自己感到难堪的时刻，学会自我解嘲。建立自己的积极形象，想象自己希望成为的样子，越细致越好，这种积极的自我暗示有助于增加对自己的接纳和信心。同时，应适当了解对方的背景和需要，即提高人际“敏感度”。",
          },
          {
            name: "抑郁",
            score: 1.92,
            factor_id: 215,
            mark: "不存在抑郁症状",
            comment:
              "测试结果表明被测者不存在抑郁症状。被测者精力旺盛，对生活充满信心和希望、兴趣广泛，自尊感高。即使偶尔遇到一些困难和挫折，也能很好应对和调节，并能从他人那里获得支持和帮助。",
            advice:
              "被测者不存在抑郁症状。希望被测者在今后的日子里继续保持开朗乐观的心态，因为生活中难免不会遇到挫折和暂时的低谷，而真正的力量来自心的力量，所以健康的心理是战胜困难的法宝。暂时的情绪低落和消沉没有什么可怕，要认识到这些情绪产生的原因，理性分析问题，调节不良情绪，选择恰当应对方式。",
          },
          {
            name: "焦虑分量表",
            score: 3.8,
            factor_id: 216,
            mark: "存在中度焦虑症状",
            comment:
              "测试结果表明被测者存在中度焦虑症状。被测者时常会有焦虑、烦躁、恐惧、紧张和不安情绪，经常为一些尚未发生的事情担心而坐立不安，小动作增多,注意力无法集中,自己也不知道为什么如此惶恐不安。对未来生活缺乏信心和乐趣.有时情绪激动,失去平衡,经常无故地发怒,与家人争吵,对什么事情都看不惯,不满意。除这些不良情绪外，往往会伴有一些躯体症状，如心悸,心慌,胸闷,气短等。",
            advice:
              "被测者存在焦虑症状。适度的焦虑有利于人们调动自身的资源（智力和体力）来应对问题，这是人类正常的应激机制，所以不必担心。但过度或长时间的焦虑对个体的身心健康有很大的危害，一方面，焦虑是许多心理症状和一些生理症状的直接原因。另一方面，过度的焦虑对人的行为、智力、人格等造成不良的影响。由于被测者目前已经存在焦虑症状，建议被测者用以下方法应对焦虑：(1)可找朋友谈，参加一些文体活动，使自己的焦虑郁闷情绪得以宣泄而达到情绪的稳定。（2）正确评价自己，既看到自己的优势，也要看到自己的不足；期望值不要定得太高，要正视现实，理想与现实之间的距离不要太大。不妨调整一下自己的目标，就能从困境中得到解脱。（3）以足够的睡眠消除疲劳，换取充沛的精力和清醒的头脑。",
          },
          {
            name: "敌对",
            score: 3.67,
            factor_id: 217,
            mark: "存在中度敌对症状",
            comment:
              "测试结果表明被测者存在中度敌对症状。对一些给自己打击的人或和自己意见相左的人产生敌对情绪，认为有些人总是居心叵测或者有意针对自己，防范心理相对比较重。可能因一些很小的事情就与人发生口角，甚至摔物或争斗。情绪易激动，且不容易控制。存在一定人际关系问题。",
            advice:
              "被测者存在敌对症状。被测者对他人的敌对情绪和行为一方面反应了自我控制力弱，另一方面表明存在认识或人格上的缺陷。比如可能不够豁达，即使很小的事情也耿耿于怀，无法站在他人的角度看到问题等。建议被测者在与人交往时，如果出现敌对的思想，要用理智来克制自己的情感，使敌意、怒气渐渐消除、化解。遇事不能鲁莽，应设身处地地替别人想一想，这样才能理解他人的观点和行为举止。在生活中，人与人之际爱你难免有误解，而一个得体的幽默，往往能使双方摆脱困窘的境地。与人叫我那个应不抱成见，寻找机会取得他人的信任，奉行以诚相待的原则。",
          },
          {
            name: "恐怖",
            score: 2.71,
            factor_id: 218,
            mark: "存在轻度恐怖症状",
            comment:
              "测试结果表明被测者存在轻度恐怖症状。被测者会有异常恐怖和害怕现象，害怕开放的空间或害怕离家（或独自在家），也包括害怕置身于人群拥挤场合以及难以逃回安全处所（多为家）的其他地方如商店、剧院、车厢等。尽管当时并无真正危险，但患者仍然极力回避所害怕的物体或处境。害怕并回避与某人或某类型人交往的情景。",
            advice:
              "被测者存在恐怖症状。如果被测者自感恐惧心理不很严重，可先从认知上接受恐惧发生时的身心变化，不要去抗拒、抑制或掩饰它；同时，进行放松训练，如感到恐惧时，有意识地做深呼吸，放松全身。对于比较严重的症状，目前最为常用的是系统脱敏法，这种方法让患者循序渐进地暴露于引起焦虑、恐惧的刺激，使患者对恐怖刺激的敏感性逐渐降低。严重的恐怖症患者还应配合药物治疗，如抗焦虑药、抗抑郁药。自感症状严重者建议寻求心理咨询师的帮助。",
          },
          {
            name: "偏执",
            score: 3.17,
            factor_id: 219,
            mark: "存在中度偏执症状",
            comment:
              "测试结果表明被测者存在中度偏执症状。被测者对周围事情的解释经常不符合实际情况，脱离实际地好争辩与敌对，固执地追求个人不够合理的“权利”或利益，且很难用说理或事实来改变被测者的想法。会有一些偏执想法和行为，如无根据的怀疑被人利用或伤害，过分自信，把错误和失败都归咎于他人，妄想某种关系的存在等等。",
            advice:
              "被测者存在偏执症状。被测者应克服多疑敏感、固执、不安全感和自我中心的人格缺陷。可以从以下几个方面进行校正：（1）与他人建立信任关系，在相互信任的基础上交流情感，以诚相待，告诉对方自己在人格上缺陷和想改变的愿望。（2）积极主动地进行交友活动，在交友中学会信任他人，消除不安全感。（3）自我反省，认识自己的偏执观念，并对这些观念加以改造，以除去其中极端偏激的成份。",
          },
          {
            name: "精神病性",
            score: 3.7,
            factor_id: 220,
            mark: "存在中度精神病性症状",
            comment:
              "测试结果表明被测者存在中度精神病性症状。有时会出现幻觉，比如凭空听到有人在说话，看到或感觉到现实情况下不存在的东西。有如下精神病性症状：感到别人有特异功能，能看穿自己的想法，对他人有强烈的防范意识，与人疏离。认为自己有一些罪恶的想法，并很自责。",
            advice:
              "被测者存在精神病性症状。被测者需要到正规医院的精神科接受进一步检查，以确定症状是否确实存在，以及对应的矫治或治疗方法，如有需要需在专业医生指导下采用心理和药物的协同方式来减轻和消除这些精神症状。",
          },
          {
            name: "其他分量表",
            score: 3,
            factor_id: 221,
            mark: "存在轻度其他症状",
            comment:
              "测试结果表明，被测者可能存在轻度其他不良症状，如失眠易醒，胃口较差等。",
            advice:
              "针对被测者可能存在的问题，建议被试者从以下几个方面予以调整：一、保持乐观、知足常乐的良好心态。对社会竞争、个人得失等有充分的认识，避免因挫折致心理失衡；二、建立有规律的日常生活作息，保持人的正常睡-醒节律；三、多进行户外体育锻炼；四、养成良好的睡眠饮食卫生习惯，如保持卧室清洁、安静、远离噪音、避开光线刺激等；饮食应多注意荤素搭配，戒烟忌酒等；五、多进行自我调节、自我暗示。可做一些放松的活动，也可反复计数等，能有效的加快入睡；六、限制白天睡眠时间，除老年人白天可适当午睡或打盹片刻外，应避免午睡或打盹，否则会减少晚上的睡意及睡眠时间；七、多多亲近自然，放松紧张烦躁的心情，可以去山区近郊旅游。通过适当的户外活动，可以让自己紧张的神经得到有效的缓解，心情好，睡眠也就好，胃口自然也可以大为改善。同时，有花草树木、山清水秀的地方，空气中的离子含量也会比较高\r\n                    ，也利于对人体神经的养护。需要特别说明的是，对于部分较重的被测者，除进行上述调节外，建议尽早就医，以便在医生的指导下进行相应更多有针对性的调节和治疗。",
          },
        ],
      },
      charDataList: [],
      charDataList2: [],
      charNameList: [],
      // ----------新--------------------
      // 图表数据
      chartDataList: [],
      // 表数据
      tableInfoList: [],
      listData1: [], //数值
      listData2: [], //参考值
      listData3: [], //图示名
      form: {},
      fontSize: "16px",
      config: {},
    };
  },
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      // //console.log("单");
      this.isDegree = false;
      data.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      // //console.log("多");
      if (this.$route.query.ids.split(",").length == 1) {
        this.isDegree = false;
      }
      data.id = this.$route.query.ids;
    }
    if (this.$route.query.isDegree) {
      this.isDegree = false;
    }
    this.degree = this.$route.query.degree;
    // ---------ajax请求---------
		loadReport(data).then((res) => {
			let array = [];
			res.data.forEach(value => {
				if( value.base_results?.name) {
					const { name = "", mark = "", score = "",comment = "" } = value.base_results;
					array.push({name, mark, score,comment});
				};
				array.push(...value.factor_results);
			});
			let obj = {...res.data[0]};
			obj.base_results = {};
			res.data = obj;
			res.data.factor_results = [...array];
			res.data.['result'] = [...array];
		  this.tmp = JSON.parse(JSON.stringify(res.config)); //获取-全局配置信息
		  // 判断是管理员还是用户端
		  if (this.tmp.user_scope_basic) {
		    this.form.scope_assessor = res.config.user_scope_assessor;
		    this.form.scope_basic = res.config.user_scope_basic;
		    this.form.scope_explain = res.config.user_scope_explain;
		    this.form.scope_factor = res.config.user_scope_factor;
		    this.form.scope_graph = res.config.user_scope_graph;
		    this.form.scope_score = res.config.user_scope_score;
		    this.form.scope_suggest = res.config.user_scope_suggest;
		    this.form.user_scope_question = res.config.user_scope_question;
		  } else {
		    this.form = res.config; //打印设置
		  }
		  let tmp = 0;
		  let infoTmp = [];
			this.inspect = res.data.inspect;
		  // 判断单个还是多个量表
		  if (this.$route.query.ids) {
		    tmp = this.$route.query.ids.split(",").length;
		  }
		  if (this.$route.query.id || tmp < 2) {
		    this.tableInfoList = [res.data];
		    infoTmp = [res.data];
		  } else {
		    this.tableInfoList = res.data;
		    infoTmp = res.data;
		  }
		
		  // 1-图表数据处理
		  let resArr = [];
		  let imgBase64 = [];
		  this.tableInfoList.forEach((v, k) => {
		    // --------------------------- 签名图片处理--------------------
		    this.convertImgToBase64(v.admin.sign_img, function (base64Img) {
		      imgBase64.push(base64Img);
		    });
		
		    // -----------------------------------共用报告数据处理------------------------------------------------
		    let thisArr = [];
		    let list1 = [];
		    let list2 = [];
		    let list3 = [];
		    // -----.base_results 添加至 数组 .factor_results----
		    //          true --- false                 true
		    // if (!(v.base_results instanceof Array) && v.base_results != {}) {
		    //   this.tableInfoList[k].factor_results.unshift(v.base_results);
		    // } else if (v.factor_results.length == 0) {
		    //   this.tableInfoList[k].factor_results.unshift(v.base_results);
		    // }
		    resArr[k] = thisArr;
		    this.listData1[k] = list1;
		    this.listData2[k] = list2;
		    this.listData3[k] = list3;
		    // ----------------------- 兼容处理 --------------------------------
		    // ------------1- 解决 程度 不存在 的数据 但是要渲染图中显示因子--------------
		    for (let i = 0; i < this.tableInfoList[k].factor_results.length; i++) {
		      this.tableInfoList[k].factor_results = this.tableInfoList[
		        k
		      ].factor_results.filter((v) => {
		        return v.mark != "";
		      });
		    }
		  });
		  this.chartDataList = resArr;
		  this.signatureImg = imgBase64;
		  // 1- 解决 程度 不存在 的数据 但是要渲染图中显示因子
		  // infoTmp.forEach((v, k) => {});
		});
    },
  mounted() {
    // 延迟1秒，渲染图表
    this.$nextTick(() => {
      let fontS =
        JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      this.fontSize = fontS;
      // this.charData();
      setTimeout(() => {
        this.fetchData();
      }, 2000);
    });
    // 打印
  },
  methods: {
    // 打印
    fetchData() {
      setTimeout(() => {
        this.fullscreenLoading = false;
        // 视图更新完成后执行
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    },
   },
  filters: {
    // 预警
    waiting(val) {
      return val != 1 ? "正常" : "危险";
    },
  },
};
</script>

<style lang="less">
@media print {
  .printbtn {
    display: none;
  }
  .PageNext {
    page-break-after: always;
  }
  .seal {
    page-break-inside: avoid;
  }
  // .page{
  //   margin: 100px 0;
  // }
}
.printbtn {
  position: absolute;
  top: 50px;
  left: 20px;
}
.main-article {
  padding: 20px;
  display: block;
  background: #fff;
}
// 报告内容部分
.content {
  box-sizing: border-box;
  width: 900px;
  margin: auto;
  background: white;
  padding: 10px 60px 10px 60px;
  // 报表头部
  .report-title {
    font-size: 30px;
    text-align: center;
  }
  //   用户信息
  .userInfo {
    margin-top: 20px;
    table {
      margin: auto;
      width: 100%;
      border-top: 1px black dashed;
      border-left: 1px black dashed;
      tr {
        width: 100%;
        // font-size: 16px;
        font-weight: 700;
        td {
          padding: 5px;
          border-bottom: 1px black dashed;
          border-right: 1px black dashed;
          &:nth-child(1) {
            width: 220px;
          }
          &:nth-child(2) {
            width: 180px;
          }
          &:nth-child(3) {
            min-width: 300px;
          }
          span {
            // font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
  //   测试结果标题.
  .test-msg {
    padding-left: 0px;
    margin-top: 10px;
    font-size: 25px;
    color: orange;
    text-shadow: black 2px 1px 1px;
    font-weight: 700;
  }
  //    图表
  .chart {
    margin-top: 10px;
    height: 350px;
    #chart-report {
      margin: 0 auto;
      //   border: black 1px solid;
    }
  }
  //   表格
  .table {
    overflow: hidden;
    margin-top: 15px;
    box-sizing: border-box;
    padding: 20px 0;
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    .table-main {
      .one,
      .two {
        width: 100%;
        border-top: 1px black dashed;
        border-left: 1px black dashed;
        tr {
          width: 100%;
          th {
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
          }
          td {
            text-align: center;
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
            font-size: 14px;
            &:nth-child(1) {
              // width: 180px;
            }
            &:nth-child(2) {
              // width: 150px;
            }
            &:nth-child(3) {
              min-width: 40px;
              text-align: center;
            }
            .table-icon {
              padding-left: 5px;
            }
          }
        }
      }
      .one {
      }
      .results-two {
        width: 100%;
        border-top: 1px dashed black;
        border-left: 1px dashed black;
        tr {
          th {
            padding: 5px 0;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            &:nth-child(1) {
              width: 160px;
            }
            &:nth-child(2) {
              width: 180px;
            }
            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }
            &:nth-child(5) {
              width: 180px;
            }
            &:nth-child(6) {
              width: 50px;
            }
          }
          td {
            box-sizing: border-box;
            padding: 2px 5px 2px 5px;
            vertical-align: middle;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            text-align: center;
            &:nth-child(1) {
              width: 160px;
            }
            &:nth-child(2) {
              width: 180px;
            }
            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }
            &:nth-child(5) {
              width: 180px;
            }
            &:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
  //   健康状态
  .condition {
    margin-top: 10px;
    border-bottom: 1px black solid;
    padding-bottom: 14px;
    table {
      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }
      .condition-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }
  //   每项分析
  .analyze {
    margin-top: 20px;
    padding-bottom: 10px;

    table {
      padding: 10px 0;
      border-bottom: 1px black solid;
      width: 100%;
      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }
      .analyze-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }
  // 用户答案
  .user-answers {
    .user-answers-item {
      margin-bottom: 10px;
      color: black;
      &:nth-child(2) {
        padding-top: 10px;
      }
      .title {
      }
      .answers {
        font-weight: 700;
        display: inline-block;
      }
    }
  }
  // 签名
  .signature {
    position: relative;
    text-align: right;
    margin-top: 40px;
    .title {
      font-weight: 700;
      .signature_img {
        display: inline-block;
        min-width: 100px;
      }
    }
    .data {
      margin-left: 20px;
    }
    img {
      // position: absolute;
      // top: -40px;
      // left: 284px;
    }
  }
}
// 明尼苏达
.minnesota {
  transform: translateX(-55px);
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .remind {
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    padding: 5px 0;
  }
  .el-divider--horizontal {
    margin: 5px 0;
  }
  .el-divider {
    background-color: rgb(133, 133, 133);
  }
  .mmpi {
    margin-top: 40px;
    .mmpi_table {
      margin-top: 20px;
      table:nth-child(1) {
        width: 100%;
        font-size: 14px;
        tr:nth-child(1) {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              // text-align: center;
              height: 120px;
              writing-mode: vertical-lr;
              letter-spacing: 8px;
            }
          }
        }
        tr:nth-child(2) {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      table:nth-child(3) {
        width: 100%;
        font-size: 14px;
        tr {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      .mmpi_summarize {
        padding: 10px 0;
      }
    }
  }
  .t {
    margin-top: 40px;
    .t_table {
      margin-top: 20px;
      > table:nth-child(1) {
        width: 100%;
        font-size: 14px;
        tr {
          display: flex;
          > td {
            flex: 1;
            display: flex;
            justify-content: center;
            > span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      > table:nth-child(2) {
        width: 100%;
        font-size: 14px;
        border: black 1px solid;
        border-collapse: collapse;
        tr {
          &:nth-child(1) {
            td:nth-child(4) {
              // display: flex;
              span {
                display: inline-block;
                width: 9%;
                justify-content: space-around;
                position: relative;
                text-align: center;
                &::after {
                  font-size: 17px;
                  content: "|";
                  position: absolute;
                  bottom: -16px;
                  right: 9px;
                  z-index: 999;
                }
                &::before {
                  font-size: 10px;
                  content: "|||||||||";
                  position: absolute;
                  bottom: -11px;
                  right: -3px;
                  z-index: 999;
                }
              }
            }
          }
          td {
            text-align: center;
            border-right: black 1px solid;
            border-bottom: black 1px solid;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 50px;
            }
            &:nth-child(3) {
              width: 180px;
            }
            &:nth-child(4) {
              position: relative;
              text-align: left;
              font-size: 10px;
            }
            &:nth-child(5) {
              width: 150px;
            }
          }
        }
        .cursor {
          color: black;
          display: inline-block;
          position: absolute;
          .el-icon-caret-top {
            font-size: 20px;
          }
        }
      }
      .forecast {
        margin-top: 30px;
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 5px 5px;
          caption {
            font-size: 16px;
            font-weight: 700;
            padding: 10px 0;
          }
          tr {
            &:nth-child(2) {
              font-weight: 700;
              td {
                &:nth-child(1) {
                  width: 120px;
                }
              }
            }
            td {
              width: 50px;
              text-align: right;
            }
          }
        }
      }
    }
  }
  .conclusion {
    margin-top: 40px;
    table {
      width: 100%;
      border-collapse: collapse;
      caption {
        padding: 5px 0;
        font-weight: 700;
        font-size: 18px;
      }
      tr {
        th {
          border-top: 1px black solid;
          border-bottom: 1px black solid;
          padding: 5px 0;
          &:nth-child(1) {
            width: 200px;
            text-align: center;
          }
          &:nth-child(2) {
            width: 150px;
            text-align: center;
          }
          &:nth-child(3) {
          }
        }
        td {
          font-size: 14px;
          padding: 5px 0;
          &:nth-child(1) {
          }
          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }
  }
  .auxiliary {
    margin-top: 40px;
    .auxiliary_title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }
    .auxiliary_main {
      .auxiliary_item {
        padding: 10px 0;
        .item_title {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }
  .describe {
    margin-top: 40px;
    .describe_main {
      .describe_content_title {
        font-size: 16px;
        text-align: center;
        p {
          margin: 30px 0px 9px 0px;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .describe_content {
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }
  .zDescribe {
    margin-top: 60px;
    .zDescribe_title {
      font-weight: 700;
      text-align: center;
    }
    .zDescribe_content {
      margin-top: 40px;
      font-size: 14px;
    }
    .zDescribe_msg {
      margin-top: 50px;
      p {
        font-weight: 700;
        span {
          font-weight: 100;
        }
      }
    }
  }
  .suggest {
    border-top: 1px black solid;
    margin-bottom: 5px;
    padding: 5px 0;
    .suggest_msg {
      font-weight: 700;
    }
    .suggest_content {
      height: 200px;
    }
  }
}
</style>
